<template>
  <el-card class="box-card">
    <div class="item">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="80px"
        class="demo-ruleForm"
        @submit.native.prevent
      >
        <el-form-item label="员工角色" prop="group_access_ids">
          <div class="flex">
            <el-checkbox-group v-model="ruleForm.group_access_ids">
              <el-checkbox
                v-for="(item) in authList"
                :key="item.jg_group_id"
                :label="item.jg_group_id"
              >
                {{ item.name }}
              </el-checkbox>
              <span class="add-box">
                <div class="addrole" @click="addrole">
                  <span>
                    <img src="~@ass/img/1.4.2/icon_xjfz备份@2x.png" alt="" />
                  </span>
                  <span class="ml5">添加角色</span>
                </div>
              </span>
            </el-checkbox-group>
          </div>
        </el-form-item>
        <el-form-item label="手机号" prop="umobile">
          <div class="flex">
            <el-input
              placeholder="请输入手机号"
              v-model.trim="ruleForm.umobile"
              maxlength="11"
            ></el-input>
            <div class="tips">提示：用于登录网校后台，请确保真实有效</div>
          </div>
        </el-form-item>
        <el-form-item label="员工昵称" prop="uname">
          <el-input
            placeholder="请输入员工昵称"
            v-model.trim="ruleForm.uname"
            show-word-limit
            maxlength="16"
          ></el-input>
        </el-form-item>
        <el-form-item label="员工头像" prop="uphoto">
          <div class="upload-contain">
            <img class="img" :src="ruleForm.uphoto" alt="" />
            <div class="right">
              <div class="text">建议尺寸500*500px，JPG、PNG格式，小于2M</div>
              <div class="up-btn">
                选择图片
                <changePhotoimg
                  :size="2"
                  :isshow="true"
                  @complete="complete"
                ></changePhotoimg>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="员工简介">
          <el-input
            type="textarea"
            placeholder="请填写员工简介"
            maxlength="255"
            show-word-limit
            :rows="7"
            v-model.trim="ruleForm.ujianjie"
          ></el-input>
        </el-form-item>
      </el-form>
      <button
        class="save-btn"
        :class="{ disable: !isSubmit }"
        @click="submitForm"
      >
        保存
      </button>
    </div>
    <transition name="fade">
      <div
        v-if="showSuccessConfirm"
        tabindex="-1"
        role="dialog"
        aria-modal="true"
        aria-label="添加成功"
        class="el-message-box__wrapper"
        style="z-index: 5; background: rgba(0, 0, 0, 0.5)"
      >
        <div class="el-message-box">
          <div class="el-message-box__header">
            <div class="el-message-box__title">
              <!---->
              <span>添加成功</span>
            </div>
            <button
              type="button"
              aria-label="Close"
              @click="close"
              class="el-message-box__headerbtn"
            >
              <i class="el-message-box__close el-icon-close"></i>
            </button>
          </div>
          <div class="el-message-box__content">
            <div class="el-message-box__container">
              <!---->
              <div class="el-message-box__message">
                <p>
                  登录账号：{{ ruleForm.umobile }}，登录地址：{{
                    origin
                  }}（使用短信验证码方式登录）
                </p>
              </div>
            </div>
            <div class="el-message-box__input" style="display: none">
              <div class="el-input">
                <!---->
                <input
                  type="text"
                  autocomplete="off"
                  placeholder=""
                  class="el-input__inner"
                />
                <!---->
                <!---->
                <!---->
                <!---->
              </div>
              <div
                class="el-message-box__errormsg"
                style="visibility: hidden"
              ></div>
            </div>
          </div>
          <div class="el-message-box__btns">
            <button
              type="button"
              @click="close"
              class="el-button el-button--default el-button--small"
            >
              <!---->
              <!---->
              <span>关闭</span>
            </button>
            <button
              type="button"
              class="
                el-button el-button--default el-button--small el-button--primary
                el-copy-button
              "
            >
              <!---->
              <!---->
              <span>复制信息</span>
            </button>
          </div>
        </div>
      </div>
    </transition>
    <el-dialog
      :visible.sync="dialogVisible"
      width="840px"
      class="dialogVisible"
    >
      <purviewSetting
        v-if="dialogVisible"
        :isAddTeacher="true"
        @cancel="cancel"
        @AddTeacherOk="AddTeacherOk"
      ></purviewSetting>
    </el-dialog>
  </el-card>
</template>

<script>
import changePhotoimg from '@cm/base/changePhotoimg'
import background from '@/mixin/background'
import { mapState } from 'vuex'
import ClipboardJS from 'clipboard'
import purviewSetting from './purviewSetting'

export default {
  name: 'addTeacher',

  components: {
    changePhotoimg,
    purviewSetting,
  },

  mixins: [background],

  data() {
    return {
      ruleForm: {
        umobile: '',
        group_access_ids: [],
        uname: '',
        ujianjie: '',
        uphoto: 'https://f.dingdingkaike.com.cn/teacher/img_mrtx%402x.png',
      },
      rules: {
        group_access_ids: [
          { required: true, message: '请选择员工角色', trigger: 'change' },
        ],
        umobile: [
          {
            trigger: 'blur',
            validator(rule, value, callback) {
              const mobile = value.trim()
              if (!mobile) {
                callback(new Error('请输入手机号'))
              } else if (!/^1[2-9]\d{9}$/.test(mobile)) {
                callback(new Error('手机号格式有误！'))
              } else {
                callback()
              }
            },
          },
        ],
        uname: [{ required: true, message: '请输入员工昵称', trigger: 'blur' }],
      },
      authList: [],

      origin: location.origin,

      showSuccessConfirm: false,
      dialogVisible:false,
    }
  },

  computed: {
    ...mapState(['userInfo']),

    isSubmit() {
      return (
        this.ruleForm.umobile &&
        /^1[2-9]\d{9}$/.test(this.ruleForm.umobile) &&
        this.ruleForm.group_access_ids.length > 0 &&
        this.ruleForm.uname
      )
    },
    // authLists(){
    //   return this.authList.concat([{
    //     id:-1
    //   }])
    // },
  },

  created() {
    this.getAuthList()
  },

  destroyed() {
    if (this.clipboard) {
      this.clipboard.destroy()
    }
  },

  methods: {
    complete(val) {
      this.ruleForm.uphoto = val
    },

    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$http({
            url: '/user/addTeacher',
            data: this.ruleForm,
            callback: () => {
              this.showSuccessConfirm = true
              this.$nextTick(() => {
                this.clipboard = new ClipboardJS('.el-copy-button', {
                  text: () => {
                    this.$root.prompt({
                      msg: '复制成功',
                      type: 'success',
                    })
                    return `登录账号：${this.ruleForm.umobile}，登录地址：${this.origin}（使用短信验证码方式登录）`
                  },
                })
              })
            },
          })
        }
      })
    },
    // 获取角色信息
    getAuthList() {
      this.$http({
        url: '/Auth/userRolesList',
        data: {
          uid: this.ruleForm.uid,
        },
        callback: ({ data }) => {
          this.authList = data
        },
      })
    },

    //添加角色
    addrole() {
      if (this.authList.length >= 28) {
        this.$root.prompt('自定义角色数量最多添加25个！')
        return
      }
      this.dialogVisible=true
    },
    // 关闭弹窗
    close() {
      this.$router.go(-1)
    },
    cancel(){
      this.dialogVisible = false
    },
    AddTeacherOk(){
      this.getAuthList()
      this.dialogVisible = false
    }
  },
}
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
  align-items: center;
}
.box-card {
  margin: 20px;
  padding: 10px;
  background: #fff;
  ::v-deep .el-form-item__content {
    margin-left: 140px !important;
  }
  ::v-deep .el-form-item {
    margin-bottom: 30px;
  }
  ::v-deep .el-form-item__label {
    text-align: left;
    color: #333;
  }
  ::v-deep .el-checkbox__label {
    color: #666;
  }
  .tips {
    font-size: 12px;
    color: #ff3535;
    margin-left: 20px;
    line-height: 18px;
  }
  .el-input,
  .el-textarea,
  .el-checkbox-group {
    width: 498px;
    &::placeholder {
      color: #999;
      border-color: #e8e8e8;
    }
  }
  .add-box {
    display: inline-block;
    vertical-align: top;
    .addrole {
      align-items: center;
      display: flex;
      // padding: 11px;
      margin-left: 10px;
      justify-content: center;
      font-size: 13px;
      color: #0aa29b;
      height: 40px;
      cursor: pointer;
      img {
        height: 14px;
        width: 14px;
      }
    }
  }
  .save-btn {
    width: 126px;
    height: 42px;
    border: 0;
    cursor: pointer;
    background: #0aa29b;
    border-radius: 4px;
    font-size: 16px;
    color: #ffffff;
    margin-top: 20px;
    text-align: center;
    &.disable {
      background: #dddddd;
    }
  }
  .upload-contain {
    display: flex;
    .img {
      width: 140px;
      height: 140px;
      border-radius: 50%;
    }
    .right {
      margin-left: 20px;
      .text {
        font-size: 12px;
        color: #666666;
        line-height: 18px;
      }
      ::v-deep .up-btn {
        border: 0;
        width: 126px;
        line-height: 40px;
        font-size: 14px;
        color: #666666;
        text-align: center;
        background: #f5f5f5;
        border-radius: 1px;
        margin-top: 24px;
        position: relative;
        border: 1px solid #bfbfbf;
        #changePhotoimg {
          width: 100%;
          height: 40px;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 3;
        }
      }
    }
  }
}

::v-deep .el-dialog {
  margin-top: 10vh !important;
}

::v-deep #purviewSetting {
  flex-direction: column;
  margin-bottom: 0px !important;
  padding: 0px;
  .right {
    width: 100%;
  }
  .preservation {
    position: relative;
    width: 100%;
    box-shadow:none;
  }
}
</style>
